import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Table } from "primeng/table";
import $ from "jquery";
import swal from "sweetalert2";
import { forkJoin, Subject } from "rxjs";
import { TasksBudgetComponent } from "../../../shared/components/budgets/tasks-budget/tasks-budget.component";

import moment from "moment";
import { RruleGeneratorComponent } from "../../../shared/components/rrule-generator/rrule-generator.component";
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  RepeatEnum,
} from "@app/_services";
import { AppConstants } from "@app/_helpers/api-constants";
import { environment } from "@environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { SharedComponentsService } from "@app/shared/components";
import { ProjectDetailsComponent } from "../project-details/project-details.component";

@Component({
  providers: [ProjectDetailsComponent],
  selector: "app-create-template",
  templateUrl: "./create-template.component.html",
})
export class CreateTemplateComponent implements OnInit {
  @ViewChild("dt") dt: Table;
  @ViewChild(TasksBudgetComponent) tasksBudgetComponent: TasksBudgetComponent;
  public isFormValid: boolean = false;
  public isRowEdit: boolean = false;
  public lastRowIndex: number = -1;
  public isLineItemsValid: boolean = false;
  public tasksDataSource: any = [];
  public myCreateForm: UntypedFormGroup;
  public AllManagerTypes: any = [];
  public StaffOptionsForTasks: any = [];
  public dateRangeList: any = [];
  public CompanyTemplateHeaderID: any = "";
  public isEditTS: boolean = false;
  public isUpdateTemplate: boolean = false;
  public isNewFormValid: boolean = false;
  public companyId;
  public resourceId;
  public showCloneDialog: boolean = false;
  public cloneTemplateName;
  counter = 0;
  public isCheckTriggered: boolean = false;
  public companyTags;
  public ddmSettingsObj: any = {};
  public isShowBudgetsDialog: boolean = false;
  public previousBudgetObj: any;
  public tasksBudgetChanged: boolean = false;
  public isEngagementChanged: boolean = false;
  public engagementList: any;
  public engValidators = [];
  public editedTemplate = [];
  public showRollOverDialog: boolean = false;
  public templateObj: {
    CompanyTemplateHeaderID: number;
    isEnableRollOver: boolean;
    isFromProject: boolean;
    isDefaultBudgetCompanyRollover: boolean;
    RollOverOption: string;
    PctOptionTime: number;
    PctOptionRev: number;
  };
  public isDisplayWildcardsTable: boolean = false;
  public isFormChanged: boolean = false;
  subscriptionLevel;
  @ViewChild(RruleGeneratorComponent) rruleComponent;
  yearsList: any = [];
  isManaging: any;
  enabledTaskBudget: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private encrDecSrvc: EncrDecrService,
    private _fb: UntypedFormBuilder,
    private router: Router,
    private http: HttpClient,
    protected mangoAPISrvc: MangoApiService,
    public mangoUtils: mangoUtils,
    private translate: TranslateService,
    private sharedService: SharedComponentsService,
    private projectDetailsComponent: ProjectDetailsComponent
  ) {
    this.sharedService.leaveAndSaveSub = new Subject<any>();
    this.sharedService.leaveAndSaveSub.subscribe(() => {
      this.saveAllTasks();
    });
    const interval = setInterval(() => {
      if (!this.translate.translations[this.translate.currentLang]) return;
      clearInterval(interval);
      this.initTranslations();
    }, 300);

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.getYears();
    this.getStaffList();
    this.initializeForm();

    const url = this.router.url;
    const urlMatch = url.match(/([0-9]+)/);
    if (urlMatch && urlMatch.length) {
      this.CompanyTemplateHeaderID = Number(urlMatch[0]);
      this.isEditTS = true;
      this.getCompanyTemplateDetails();
    }

    this.previousBudgetObj = {
      isTrackTimeTaskLevel: false,
      TimeAmount: "0.00",
      BudgetYear: new Date().getFullYear(),
    };

    const templateName = this.encrDecSrvc.getObject(AppConstants.projectTemplateName);
    if (!this.isEditTS && templateName) {
      this.isFormValid = true;
      this.isLineItemsValid = true;
      this.myCreateForm.controls["TemplateName"].setValue(templateName);
      this.myCreateForm.controls["DueDate"].setValue(new Date());
      const tasksToClone = this.encrDecSrvc.getObject(AppConstants.tasksToClone);
      if (tasksToClone != null) {
        tasksToClone.forEach((task) => {
          task.IsNewRecord = true;
        });
        this.tasksDataSource = tasksToClone;
      }
      this.encrDecSrvc.removeObject(AppConstants.tasksToClone);
      this.encrDecSrvc.removeObject(AppConstants.projectTemplateName);
    }

    this.getShortCutLabels();
    /* End - For Shortcut labels */
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant("Project-Management") },
      { label: this.translate.instant("dm.Create_Template"), icon: "ic-red" },
    ]);
    this.dateRangeList = [
      { label: this.translate.instant("none"), value: "none" },
      { label: this.translate.instant("yearly"), value: "year" },
      { label: this.translate.instant("quarterly"), value: "quarterly" },
      { label: this.translate.instant("user.monthly"), value: "monthly" },
      { label: this.translate.instant("semi_monthly"), value: "semi-monthly" },
      { label: this.translate.instant("user.weekly"), value: "week" },
      { label: this.translate.instant("semi_weekly"), value: "semi-weekly" },
      { label: this.translate.instant("user.daily"), value: "daily" },
      { label: this.translate.instant("Custom"), value: "custom" },
    ];
  }

  ngOnInit(): void {
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
    this.fetchDdmSettings();
    this.getEngagements();
  }

  getYears() {
    if (this.yearsList?.length > 0) return;

    const value = 5;
    const yearToday = new Date().getFullYear();
    for (let x = yearToday + value; x >= yearToday - 3; x--) {
      this.yearsList.push({ label: x.toString(), value: x });
    }
  }

  fetchDdmSettings() {
    const parent = this;

    parent.companyTags = parent.encrDecSrvc.getObject(AppConstants.companyTags);

    if (!parent.companyTags) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.fetchDDMSettings(parent.companyId).subscribe(
        (res: any) => {
          if (res.length > 0) parent.companyTags = res[0];
          else {
            parent.companyTags = {
              CompanyID: "",
              IsEmailProjectAssigned: false,
              IsEmailTaskAssigned: false,
              IsEmailTaskReady: false,
              IsManagerProjectInProgress: false,
              IsManagerReadyReview: false,
              IsProjectRejected: false,
              Tag1: "Available for Work",
              Tag2: "Extended",
              Tag3: "Urgent",
              Tag4: "Missing Information",
              Tag5: "Waiting on Client",
              Tag6: "Notice",
              Tag7: "Telephone Call",
              Tag8: "Tag 8",
              Tag9: "Tag 9 ",
              ddmSettingsID: null,
              DefaultUserID: null,
              DefaultManagerID: null,
              isTrackingTimebyProjects: [false],
              isBudgetingProjectLevel: [false],
            };
          }

          parent.encrDecSrvc.addObject(AppConstants.companyTags, parent.companyTags);

          if (parent.companyTags?.isBudgetingProjectLevel) {
            parent.myCreateForm
              .get("EngagementTypeID")
              .setValidators(parent.engValidators.concat(Validators.required));
          } else {
            parent.myCreateForm.get("EngagementTypeID").setValidators(parent.engValidators);
          }

          parent.mangoAPISrvc.showLoader(false);
        },
        (error) => {
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      if (parent.companyTags?.isBudgetingProjectLevel) {
        parent.myCreateForm
          .get("EngagementTypeID")
          .setValidators(parent.engValidators.concat(Validators.required));
      } else {
        parent.myCreateForm.get("EngagementTypeID").setValidators(parent.engValidators);
      }
    }
    parent.ddmSettingsObj = parent.companyTags;
  }

  ngOnDestroy() {
    this.sharedService.leaveAndSaveSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    const parent = this;
    $(".ui-widget-header").click(function (e) {
      parent.stopEdit(e);
    });

    parent.myCreateForm.valueChanges.subscribe((data) => {
      parent.validateForm();
    });
  }

  stopEdit(event) {
    event.stopPropagation();
    $(this.dt.editingCell).removeClass("ui-editing-cell");
    this.dt.editingCell = null;
  }

  onProjectMemoChange(value: string) {
    this.myCreateForm.get('ProjectMemo')?.setValue(value);
    this.myCreateForm.get('ProjectMemo')?.markAsDirty();
    this.validateForm();
  }
  
  getCompanyTemplateDetails() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateDetails(parent.CompanyTemplateHeaderID).subscribe(
      (data) => {
        parent.updateTemplateForm(data[0]);
        parent.getProjectTemplates();
        parent.mangoAPISrvc.showLoader(false);
      },
      (error) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  createProjectTemplate(id?, isClone?) {
    const parent = this;
    const obj = parent.myCreateForm.value;
    parent.isFormValid = false;
    delete obj["CompanyTemplateHeaderID"];
    obj["DueDate"] = obj["DueDate"] !== "" ? obj["DueDate"] : null;
    obj["ExtensionDate"] = obj["ExtensionDate"] !== "" ? obj["ExtensionDate"] : null;
    obj["DateReceived"] =
      obj["DateReceived"] !== ""
        ? moment.utc(obj["DateReceived"], "YYYY-MM-DD").toISOString()
        : null;
    // obj["EngagementTypeID"] = obj["EngagementTypeID"]
    //   ? obj["EngagementTypeID"]?.EngagementTypeID
    //   : null;

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.createCompanyTemplateHeader(obj).subscribe(
      (data: any) => {
        parent.mangoAPISrvc.notify("success", parent.translate.instant("Success"), data.message);
        parent.isNewFormValid = true;
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
        parent.CompanyTemplateHeaderID = data.id;
        parent.isEditTS = true;
        parent.isUpdateTemplate = true;
        parent.myCreateForm.controls["CompanyTemplateHeaderID"].setValue(
          parent["CompanyTemplateHeaderID"]
        );

        const logdata = {};
        if (isClone) logdata["Action"] = "Clone Project Template #" + data.id + " From #" + id;
        else logdata["Action"] = "Create Project Template #" + data.id;
        logdata["Description"] =
          "Name: " +
          obj.TemplateName +
          " - EngagementID: " +
          obj.EngagementTypeID +
          " - BillingPartner:" +
          obj.ManagerID;
        if (obj.DueDate)
          logdata["Description"] += " - DueDate: " + moment(obj.DueDate).format("YYYY-MM-DD");
        logdata["Table"] = "";

        if (!this.isManaging) {
          this.mangoAPISrvc.addUserLogs(logdata).subscribe(
            (res) => {},
            (err) => {
              console.log(err);
            }
          );
        }

        parent.tasksDataSource = parent.tasksDataSource.map((x) => {
          delete x.CompanyTemplateDetailsID;
          x.CompanyTemplateHeaderID = data.id;
          return x;
        });
        parent.saveAllTasks(isClone);
        parent.mangoAPISrvc.showLoader(false);
      },
      (data) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  onFormChanged(data) {
    this.isFormChanged = true;
    this.validateForm();
    data["isEditRecord"] = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
  }

  saveAllTasks(isClone?) {
    const parent = this;
    const obj = parent.myCreateForm.value;
    if (
      obj.Repeat !== null &&
      obj.Repeat !== "" &&
      obj.Repeat !== "none" &&
      obj.Repeat !== "custom"
    ) {
      const isValid = parent.projectDetailsComponent.validateEnteredWildcards(
        obj["TemplateName"],
        obj["Repeat"] as RepeatEnum
      );
      if (!isValid) return;
    }

    if (!this.isEditTS) {
      this.createProjectTemplate();
    } else {
      parent.saveTemplate();
      parent.batchSaveAllTasks().subscribe((data) => {
        data.forEach((task: any) => {
          const logdata = {};
          if (task.message === "Successfully created") {
            if (isClone)
              logdata["Action"] =
                "Clone Project Task #" +
                task.data.CompanyTemplateDetailsID +
                " From #" +
                task.data.CompanyTemplateHeaderID;
            else
              logdata["Action"] =
                "Create Project Task #" +
                task.data.CompanyTemplateDetailsID +
                " From #" +
                task.data.CompanyTemplateHeaderID;
          } else {
            logdata["Action"] =
              "Update Project Task #" +
              task.data.CompanyTemplateDetailsID +
              " From #" +
              task.data.CompanyTemplateHeaderID;
          }
          logdata["Description"] = "TaskDescription: " + task.data.TaskDescription;
          logdata["Description"] += task.data.DueDate
            ? " - DueDate: " + moment(task.data.DueDate).format("YYYY-MM-DD")
            : "";
          logdata["Description"] += " - Assigned: " + task.data.UserAssignedIDArray;
          logdata["Table"] = "";

          if (!this.isManaging) {
            this.mangoAPISrvc.addUserLogs(logdata).subscribe(
              (res) => {},
              (err) => {
                console.log(err);
              }
            );
          }
        });
        parent.getProjectTemplates();
        this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
      });
    }
    this.isFormChanged = false;
  }

  batchSaveAllTasks() {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", parent.encrDecSrvc.getObject(AppConstants.token));
    parent.tasksDataSource.map(function (lineObj, index) {
      lineObj["TaskRow"] = index + 1;
    });
    const createRecords = parent.tasksDataSource.filter((item) => item["IsNewRecord"] === true);
    createRecords.forEach((selectedItem, key) => {
      delete selectedItem["StaffName"];
      delete selectedItem["IsNewRecord"];
      delete selectedItem["CompanyTemplateDetailsID"];
      selectedItem["CompanyTemplateHeaderID"] = parent.CompanyTemplateHeaderID;
      selectedItem["DueDate"] = selectedItem["DueDate"]
        ? moment.utc(selectedItem["DueDate"]).toDate().toISOString()
        : null;

      selectedItem["UserAssignedIDArray"] =
        selectedItem["UserAssignedIDArray"]?.length > 0
          ? selectedItem["UserAssignedIDArray"]
          : null;
      observableBatch.push(
        parent.http.post(`${environment.API_URL}/ddm/companyTemplateDetails/create`, selectedItem, {
          headers: headers,
          withCredentials: true,
        })
      );
    });
    const updateRecords = parent.tasksDataSource.filter((item) => item["IsNewRecord"] === false);
    updateRecords.forEach((selectedItem, key) => {
      delete selectedItem["StaffName"];
      delete selectedItem["IsNewRecord"];
      delete selectedItem["StaffName"];
      selectedItem["UserAssignedIDArray"] =
        selectedItem["UserAssignedIDArray"]?.length > 0
          ? selectedItem["UserAssignedIDArray"]
          : null;
      selectedItem["DueDate"] = selectedItem["DueDate"]
        ? moment.utc(selectedItem["DueDate"]).toDate().toISOString()
        : null;
      observableBatch.push(
        parent.http.put(
          `${environment.API_URL}/ddm/companyTemplateDetails/update/${selectedItem["CompanyTemplateDetailsID"]}`,
          selectedItem,
          { headers: headers, withCredentials: true }
        )
      );
    });
    return forkJoin(observableBatch);
  }

  handleDropSelectClick(event: any, data: any) {
    // data["UserAssigned"] = event.value;
    if (event.value) {
      data["UserAssignedIDArray"] = event.value;
    }
    data["isEditRecord"] = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.getStaffNames(event.value, data);
    this.validateForm();
    this.validateLineItems();
  }

  onRowChange(event) {
    const dragRow = this.tasksDataSource[event.dragIndex];
    dragRow["isEditRecord"] = true;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.validateForm();
    this.validateLineItems();
  }

  saveTemplate() {
    const parent = this;
    const obj = parent.myCreateForm.value;
    obj["DateReceived"] =
      obj["DateReceived"] != ""
        ? moment.utc(obj["DateReceived"], "YYYY-MM-DD").toISOString()
        : null;
    obj["DueDate"] = obj["DueDate"] != "" ? obj["DueDate"] : null;
    obj["ExtensionDate"] = obj["ExtensionDate"] != "" ? obj["ExtensionDate"] : null;
    obj["ProjectMemo"] = obj["ProjectMemo"] || "";
    // obj["EngagementTypeID"] = obj["EngagementTypeID"]
    //   ? obj["EngagementTypeID"]?.EngagementTypeID
    //   : null;
    parent.isFormValid = false;
    parent.isEngagementChanged = false;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateCompanyTemplateDetails(obj).subscribe(
      (data: any) => {
        parent.mangoAPISrvc.notify("success", parent.translate.instant("Success"), data.message);
        parent.mangoAPISrvc.showLoader(false);

        if (!parent.isUpdateTemplate) {
          const logdata = {};
          logdata["Action"] = "Update Project Template #" + obj.CompanyTemplateHeaderID;
          logdata["Description"] =
            "Name: " +
            obj.TemplateName +
            " - EngagementID: " +
            obj.EngagementTypeID +
            " - BillingPartner:" +
            obj.ManagerID;
          if (obj.DueDate)
            logdata["Description"] += " - DueDate: " + moment(obj.DueDate).format("YYYY-MM-DD");
          logdata["Table"] = "";

          if (!this.isManaging) {
            this.mangoAPISrvc.addUserLogs(logdata).subscribe(
              (res) => {},
              (err) => {
                console.log(err);
              }
            );
          }
        }
        parent.isUpdateTemplate = false;
      },
      (data) => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getStaffNames(ids, data) {
    if (!ids || ids.length == 0) {
      data["StaffNames"] = "Unassigned";
      return;
    }
    const labels = this.AllManagerTypes.filter((item) => ids.includes(item["value"])).map(
      (item) => item["StaffName"]
    );
    data["StaffNames"] = labels.join(", ");
  }

  getProjectTemplates() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getCompanyTemplateHeader(parent.CompanyTemplateHeaderID).subscribe(
      (data: any) => {
        data.map(function (lineObj, index) {
          parent.getStaffNames(lineObj["UserAssignedIDArray"], lineObj);
          lineObj.IsNewRecord = false;
          lineObj.isEditRecord = false;
          lineObj.DueDate = lineObj.DueDate ? new Date(lineObj.DueDate) : null;
          lineObj["TaskRow"] = lineObj["TaskRow"] ? lineObj["TaskRow"] : index + 1;
          // lineObj.StaffName = lineObj.UserAssigned != null? lineObj.StaffName : "Unassigned";
          lineObj.UserAssigned = lineObj.UserAssigned != null ? lineObj.UserAssigned : -1;
        });
        data.sort(function (a, b) {
          return a.TaskRow - b.TaskRow;
        });
        parent.tasksDataSource = data;
        if (parent.tasksDataSource.length == 0) {
          //this.addRow();
        } else {
          parent.validateLineItems();
        }
        parent.mangoAPISrvc.showLoader(false);
      },
      (err) => {
        parent.mangoAPISrvc.notify(
          "error",
          parent.translate.instant("error"),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getEngagements() {
    this.engagementList = [];
    this.mangoAPISrvc.getEngagementTypes(this.companyId).subscribe((result: any) => {
      for (let i = 0; i < result.length; i++) {
        if (!result[i].Inactive) {
          this.engagementList.push({
            label: result[i].Description,
            value: result[i].EngagementTypeID,
          });
        }
      }

      this.myCreateForm.controls["EngagementTypeID"].setValue(
        this.myCreateForm.controls["EngagementTypeID"].value
      );
    });
  }

  onClone() {
    this.cloneTemplateName = this.myCreateForm.controls["TemplateName"].value;
    this.showCloneDialog = !this.showCloneDialog;
  }

  addRow(id?) {
    if (!this.validateLineItems() && this.tasksDataSource.length > 0) {
      this.scrollDownToNewRow(id);
      return;
    }
    const lineObj = {};
    lineObj["UserAssigned"] = this.resourceId;
    lineObj["UserAssignedIDArray"] = this.resourceId ? [this.resourceId] : null;
    lineObj["CompanyID"] = this.companyId;
    lineObj["TaskDescription"] = null;
    lineObj["TaskMemo"] = "";
    lineObj["DueDate"] = null;
    lineObj["CompanyTemplateHeaderID"] = this.CompanyTemplateHeaderID;
    lineObj["dueDateOffsetDaysIndicator"] = 1;
    lineObj["IsNewRecord"] = true;
    lineObj["isEditRecord"] = true;
    lineObj["TaskRow"] = this.tasksDataSource.length + 1;

    this.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    this.getStaffNames(lineObj["UserAssignedIDArray"], lineObj);
    this.tasksDataSource.push(lineObj);
    this.validateForm();
    this.validateLineItems();
    setTimeout(() => {
      this.onRowEditInit();
    }, 100);
    setTimeout(() => {
      this.scrollDownToNewRow(id);
    }, 200);
  }

  onRowEditInit() {
    const el = document.querySelectorAll(".new-row");
    this.dt.editingCell = el[0];
  }

  onRowEditInit1(data, column, index) {
    this.removeRowEditMode(true);
    this.dt.editingRowKeys[data.TaskRow] = true;

    if (this.isRowEdit && this.lastRowIndex == index) return;

    this.lastRowIndex = index;

    if (column === "TaskDescription") {
      this.selectCell("task-desc", index);
    } else if (column === "DueDate") {
      this.selectCell("due-date", index);
    } else if (column === "TaskMemo") {
      this.selectCell("task-memo", index);
    } else if (column === "StaffName") {
      this.selectCell("staff-name", index, ".p-multiselect-trigger");
    } else if (column === "OffsetDays") {
      this.selectCell("offset-days", index);
    }

    this.isRowEdit = true;
  }

  removeRowEditMode(doNotRemoveFlag?) {
    this.dt.editingRowKeys = {};

    if (!doNotRemoveFlag) this.isRowEdit = false;
  }

  checkIfRemoveEdit() {
    if (this.isCheckTriggered) return;

    this.isCheckTriggered = true;

    setTimeout(() => {
      const matches =
        document.activeElement.className.match(/tasks-row-edit/) ||
        document.activeElement.className.match(/p-datepicker/) ||
        document.activeElement.className.match(/p-multiselect/) ||
        document.activeElement.className.match(/p-inputtext/) ||
        document.activeElement?.tagName == "INPUT"
          ? [1]
          : [];
      if (!matches || matches?.length == 0) {
        this.removeRowEditMode();
      }
      this.isCheckTriggered = false;
    }, 150);
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`;
      colClass += xtraEl ? ` > ${xtraEl}` : "";
      if (clss == "staff-name") {
        $(colClass).click();
      } else $(colClass).select();
    }, 50);
  }

  scrollDownToNewRow(id) {
    if (id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const el1 = document.querySelectorAll(".new-row");
        el1[0].querySelectorAll("input")[0]?.focus();
      }, 500);
    }
  }

  /* Start - For Shortcut labels */
  getShortCutLabels() {
    const parent = this;

    parent.myCreateForm.valueChanges.subscribe((data) => {
      data.ProjectMemo = parent.replaceShortcuts(data.ProjectMemo);
      parent.myCreateForm.controls["ProjectMemo"].setValue(data.ProjectMemo, { emitEvent: false });
    });
  }

  replaceShortcuts(value, property?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut["ShortCutCode"]) {
          label = shortcut["Phrase"];
        }
      }

      valueArr[i] = label;
    }

    return valueArr.join(" ");
  }
  /* End - For Shortcut labels */

  deleteRow(event: any, itemData: any) {
    const self = this;
    event.stopPropagation();
    if (itemData.IsNewRecord) {
      const index = this.tasksDataSource.indexOf(itemData);
      this.tasksDataSource.splice(index, 1);
    } else {
      swal
        .fire({
          title: self.translate.instant("confirmation"),
          text: self.translate.instant("pm.do-you-want-to-delete-this-record"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: self.translate.instant("yes_delete"),
          cancelButtonText: self.translate.instant("no_delete"),
        })
        .then((result) => {
          if (result.value) {
            self.mangoAPISrvc.showLoader(true);
            self.mangoAPISrvc
              .deleteCompanyTemplateDetails(itemData.CompanyTemplateDetailsID)
              .subscribe(
                (data) => {
                  const index = self.tasksDataSource.indexOf(itemData);
                  self.tasksDataSource.splice(index, 1);
                  swal.fire({
                    icon: "success",
                    title: self.translate.instant("deleted"),
                    text: AppConstants.deleteMessage,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  self.mangoAPISrvc.showLoader(false);
                  const logdata = {};
                  logdata["Action"] =
                    "Deleted Task #" +
                    itemData.CompanyTemplateDetailsID +
                    " From #" +
                    itemData.CompanyTemplateHeaderID;
                  logdata["Description"] = "Description: " + itemData.TaskDescription;
                  if (itemData.TaskMemo !== "")
                    logdata["Description"] += " - Memo: " + itemData.TaskMemo;
                  else if (itemData.DueDate)
                    logdata["Description"] += " - DueDate: " + itemData.DueDate;
                  logdata["Description"] += " - Assigned User: " + itemData.UserAssignedIDArray;
                  logdata["Table"] = "";

                  if (!this.isManaging) {
                    this.mangoAPISrvc.addUserLogs(logdata).subscribe(
                      (res) => {},
                      (err) => {
                        console.log(err);
                      }
                    );
                  }
                },
                (data: any) => {
                  const res = data.json();
                  swal.fire({
                    icon: "error",
                    title: self.translate.instant("error"),
                    text: res.message,
                    showConfirmButton: false,
                    timer: 2500,
                  });
                  self.mangoAPISrvc.showLoader(false);
                }
              );
          }
        });
    }
  }

  updateTemplateForm(itemData) {
    if (Object.keys(itemData).length > 0) {
      this.myCreateForm.controls["CompanyTemplateHeaderID"].setValue(
        itemData["CompanyTemplateHeaderID"]
      );
      this.myCreateForm.controls["CompanyID"].setValue(itemData["CompanyID"]);
      itemData["DateReceived"] = itemData["DateReceived"]
        ? this.mangoUtils.removeOffset(new Date(itemData["DateReceived"]))
        : null;
      this.myCreateForm.controls["DateReceived"].setValue(itemData["DateReceived"]);
      itemData["DueDate"] = itemData["DueDate"] ? moment(itemData["DueDate"]).toDate() : null;
      this.myCreateForm.controls["DueDate"].setValue(itemData["DueDate"]);
      itemData["ExtensionDate"] = itemData["ExtensionDate"]
        ? new Date(itemData["ExtensionDate"])
        : null;
      this.myCreateForm.controls["ExtensionDate"].setValue(itemData["ExtensionDate"]);
      this.myCreateForm.controls["ManagerID"].setValue(itemData["ManagerID"]);
      this.myCreateForm.controls["UserAssignedID"].setValue(itemData["UserAssignedID"]);
      this.myCreateForm.controls["DateReceived"].setValue(itemData["DateReceived"]);
      this.myCreateForm.controls["ProjectMemo"].setValue(itemData["ProjectMemo"]);
      this.myCreateForm.controls["Repeat"].setValue(itemData["Repeat"]);
      this.myCreateForm.controls["TemplateName"].setValue(itemData["TemplateName"]);
      this.myCreateForm.controls["isReviewRequired"].setValue(itemData["isReviewRequired"]);
      this.myCreateForm.controls["TurnAround"].setValue(itemData["TurnAround"]);
      this.myCreateForm.controls["IsCompleteInOrder"].setValue(itemData["IsCompleteInOrder"]);

      this.myCreateForm.controls["isPreviousPeriodYearPolicy"].setValue(
        itemData["isPreviousPeriodYearPolicy"]
      );

      this.myCreateForm.controls["automaticComputeDuedate"].setValue(
        itemData["automaticComputeDuedate"]
      );

      this.myCreateForm.controls["EngagementTypeID"].setValue(itemData["EngagementTypeID"]);

      this.myCreateForm.controls["TimeAmount"].setValue(itemData["TimeAmount"]);

      this.myCreateForm.controls["isTrackTimeTaskLevel"].setValue(itemData["isTrackTimeTaskLevel"]);

      itemData["BudgetYear"] = itemData["BudgetYear"] || new Date().getFullYear();

      this.myCreateForm.controls["BudgetYear"].setValue(
        itemData["BudgetYear"] || new Date().getFullYear()
      );

      this.previousBudgetObj = {
        isTrackTimeTaskLevel: itemData["isTrackTimeTaskLevel"],
        TimeAmount: itemData["TimeAmount"],
        BudgetYear: itemData["BudgetYear"], //todo
      };

      this.templateObj = {
        CompanyTemplateHeaderID: this.CompanyTemplateHeaderID,
        isEnableRollOver: itemData.isEnableRollOver || true,
        isFromProject: true,
        isDefaultBudgetCompanyRollover: itemData.isDefaultBudgetCompanyRollover || false,
        RollOverOption: itemData.RollOverOption || "existing",
        PctOptionTime: itemData.PctOptionTime || 0,
        PctOptionRev: itemData.PctOptionRev || 0,
      };

      this.myCreateForm.controls["RuleString"].setValue(itemData["RuleString"] || "");
      this.myCreateForm.updateValueAndValidity();
    }
  }

  initializeForm() {
    this.myCreateForm = this._fb.group({
      CompanyTemplateHeaderID: [""],
      CompanyID: [this.companyId],
      DateReceived: [""],
      DueDate: [""],
      ExtensionDate: [""],
      ManagerID: [this.resourceId],
      UserAssignedID: [this.resourceId],
      ProjectMemo: [""],
      Repeat: [""],
      TurnAround: [null],
      TemplateName: ["", [<any>Validators.required]],
      isReviewRequired: [false],
      IsCompleteInOrder: [false],
      automaticComputeDuedate: [false],
      isTrackTimeTaskLevel: [false],
      BudgetYear: [null],
      EngagementTypeID: [null],
      TimeAmount: [null],
      RuleString: [null],
      isPreviousPeriodYearPolicy: [true],
    });
  }

  getStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    this.StaffOptionsForTasks = [];
    this.AllManagerTypes.unshift({
      label: "Unassigned",
      StaffName: "Unassigned",
      value: -1,
      StaffID: -1,
      Email: null,
    });
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive) continue;

      const obj = {};
      obj["label"] = item[i]["StaffName"];
      obj["value"] = item[i]["StaffID"];
      obj["StaffID"] = item[i]["StaffID"];
      obj["StaffName"] = item[i]["StaffName"];
      obj["Email"] = item[i]["Email"];
      obj["StaffNumber"] = item[i]["StaffNumber"];
      this.AllManagerTypes.push(obj);
      this.StaffOptionsForTasks.push(obj);
    }
  }

  validateForm() {
    const parent = this;
    let isInValidData = false;
    Object.keys(parent.myCreateForm.controls).forEach((key) => {
      if (parent.myCreateForm.get(key).invalid && parent.myCreateForm.dirty) {
        isInValidData = true;
      }
    });
    if ((!isInValidData && parent.myCreateForm.dirty) || parent.isFormChanged) {
      parent.isFormValid = true;
      parent.isFormChanged = true;
    } else {
      parent.isFormValid = false;
      parent.isFormChanged = false;
    }
    parent.validateLineItems();
  }

  validateLineItems() {
    this.isLineItemsValid = true;
    this.isFormChanged = true;
    let validData = true;
    for (let i = 0; i < this.tasksDataSource.length; ++i) {
      const obj = this.tasksDataSource[i];
      if (obj["TaskDescription"] == null || obj["TaskDescription"] == "") {
        validData = false;
        this.isLineItemsValid = false;
        this.isFormChanged = false;
        break;
      }
    }
    if (this.isLineItemsValid && !this.isFormValid) {
      this.isLineItemsValid = false;
      this.isFormChanged = false;
    }
    return validData;
  }

  createClone(id) {
    this.isEditTS = false;
    this.myCreateForm.controls["TemplateName"].setValue(this.cloneTemplateName);
    this.tasksDataSource = this.tasksDataSource.map((x) => {
      x.IsNewRecord = true;
      return x;
    });

    this.encrDecSrvc.addObject(AppConstants.projectTemplateName, this.cloneTemplateName);
    this.encrDecSrvc.addObject(AppConstants.tasksToClone, this.tasksDataSource);

    this.createProjectTemplate(id, true);
    this.showCloneDialog = false;
  }

  onShowTasksBudget() {
    if (this.subscriptionLevel !== "ENTERPRISE" && this.subscriptionLevel !== "FREE") {
      swal.fire({
        title: this.translate.instant("access_denied"),
        html: "<div>This feature is only available for <strong>PRO</strong> plan.</div>", //ENTERPRISE is now labeled as PRO
        icon: "warning",
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: "Ok",
      });
      return;
    }
    this.isShowBudgetsDialog = true;
  }

  onCloseBudgetDialog() {
    this.isShowBudgetsDialog = false;
    if (this.shouldDisableBudget()) {
      this.tasksBudgetChanged = false;
      this.myCreateForm.controls["isTrackTimeTaskLevel"].setValue(
        this.previousBudgetObj.isTrackTimeTaskLevel
      );
      this.myCreateForm.controls["BudgetYear"].setValue(this.previousBudgetObj.BudgetYear);
      this.myCreateForm.controls["TimeAmount"].setValue(this.previousBudgetObj.TimeAmount);
    }
  }

  onTasksBudgetUpdated({ isUpdated }) {
    this.tasksBudgetChanged = isUpdated;
  }

  shouldDisableBudget() {
    const obj = {
      isTrackTimeTaskLevel: this.myCreateForm.controls["isTrackTimeTaskLevel"].value,
      TimeAmount: this.myCreateForm.controls["TimeAmount"].value,
      BudgetYear: this.myCreateForm.controls["BudgetYear"].value,
    };

    return !(
      this.previousBudgetObj.isTrackTimeTaskLevel !== obj.isTrackTimeTaskLevel ||
      (obj.TimeAmount && this.previousBudgetObj.TimeAmount !== obj.TimeAmount) ||
      (obj.BudgetYear && this.previousBudgetObj.BudgetYear !== obj.BudgetYear) ||
      this.tasksBudgetChanged
    );
  }

  onSaveBudget() {
    const obj = {
      isTrackTimeTaskLevel: this.myCreateForm.controls["isTrackTimeTaskLevel"].value,
      BudgetYear: this.myCreateForm.controls["BudgetYear"].value,
      CompanyTemplateHeaderID: this.myCreateForm.controls["CompanyTemplateHeaderID"].value,
    };

    this.enabledTaskBudget = this.myCreateForm.controls["isTrackTimeTaskLevel"].value;

    if (!obj.isTrackTimeTaskLevel) {
      obj["TimeAmount"] = this.myCreateForm.controls["TimeAmount"].value || "0.00";
    }

    console.log("Saving...", obj);
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateCompanyTemplateDetails(obj).subscribe(
      (data: any) => {
        this.previousBudgetObj = { ...obj };
        // this.isFormValid = false
        if (!this.enabledTaskBudget) {
          const logdata = {};
          logdata["Action"] = "Update Project Budget From #" + data.id;
          logdata["Description"] =
            "Project Budgeted Time: " +
            this.myCreateForm.controls["TimeAmount"].value +
            " - BudgetYear: " +
            obj.BudgetYear +
            " - TaskBudget: " +
            obj.isTrackTimeTaskLevel;
          logdata["Table"] = "";

          if (!this.isManaging) {
            this.mangoAPISrvc.addUserLogs(logdata).subscribe(
              (res) => {},
              (err) => {
                console.log(err);
              }
            );
          }
        }

        if (obj.isTrackTimeTaskLevel && this.tasksBudgetChanged) {
          this.tasksBudgetComponent.onSaveTemplate();
          this.tasksBudgetChanged = false;
        } else {
          this.mangoAPISrvc.notify(
            "success",
            this.translate.instant("Success"),
            AppConstants.updateMsg
          );
          this.mangoAPISrvc.showLoader(false);
        }
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          "error",
          this.translate.instant("Error"),
          AppConstants.updateErrorMsg
        );
      }
    );
  }

  onTrackTimeChanged() {
    // this.myCreateForm.controls['TimeAmount'].setValue('0.00')
  }

  onTasksBudgetSaved(event) {
    const totalTimeAmount = event.totalTimeAmount || 0;
    this.myCreateForm.controls["TimeAmount"].setValue(
      this.mangoUtils.doMoneyFormat(totalTimeAmount, "time")
    );

    this.previousBudgetObj.TimeAmount = this.myCreateForm.value["TimeAmount"];

    if (this.enabledTaskBudget) {
      event.budgetTasks.forEach((task) => {
        const logdata = {};
        logdata["Action"] = "Update Task Budget From #" + task.CompanyTemplateHeaderID;
        logdata["Description"] =
          "CompanyTemplateDetailsID: " +
          task.CompanyTemplateDetailsID +
          " - BudgetedTime: " +
          task.TimeAmount;
        logdata["Table"] = "";

        if (!this.isManaging) {
          this.mangoAPISrvc.addUserLogs(logdata).subscribe(
            (res) => {},
            (err) => {
              console.log(err);
            }
          );
        }
      });
    }

    if (event.budgetTasks?.length > 0) {
      this.tasksDataSource = this.tasksDataSource.map((templateTask) => {
        return {
          ...templateTask,
          TimeAmount:
            event.budgetTasks.filter(
              (budgetTask) =>
                budgetTask.CompanyTemplateDetailsID == templateTask.CompanyTemplateDetailsID
            )[0]?.SavedTimeAmount || 0,
        };
      });
    }
  }

  onShowRolloverDialog() {
    this.showRollOverDialog = true;
  }

  onCloseRolloverDialog(event) {
    if (event.isSaved) {
      this.templateObj = {
        CompanyTemplateHeaderID: this.CompanyTemplateHeaderID,
        isEnableRollOver: event.isEnableRollOver,
        isFromProject: true,
        isDefaultBudgetCompanyRollover: event.isDefaultBudgetCompanyRollover,
        RollOverOption: event.RollOverOption,
        PctOptionTime: event.PctOptionTime,
        PctOptionRev: event.PctOptionRev,
      };
    }
    this.showRollOverDialog = false;
  }

  onCompleteRruleDialog(event) {
    if (event.isCompleted) {
      this.myCreateForm.controls["DueDate"].setValue(event.dueDate);

      this.myCreateForm.controls["RuleString"].setValue(event.rrule);
    }
  }

  onChangeRepeat() {
    if (this.myCreateForm.value["Repeat"] == "custom") {
      this.rruleComponent.isDisplayRuleDialog = true;
      this.rruleComponent.ngOnInit();
    }
  }

  onOffsetDaysChange(evt, index) {
    if (evt < 1 && index >= 0) this.tasksDataSource[index].dueDateOffsetDaysIndicator = 1;
  }

  onOpenWildcardsDialog() {
    const formObj = this.myCreateForm.value;
    if (
      formObj.Repeat === null ||
      formObj.Repeat === "" ||
      formObj.Repeat === "none" ||
      formObj.Repeat === "custom"
    ) {
      swal.fire({
        title: this.translate.instant("Warning"),
        text: this.translate.instant(`pm.wildcards_not_allowed_on_empty_repeat`),
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: this.translate.instant("Ok"),
      });
      return;
    }

    this.isDisplayWildcardsTable = true;
  }
}
