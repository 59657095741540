import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Subscription, timer } from 'rxjs';
import { select } from '@syncfusion/ej2-angular-schedule';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html'
})
export class ClientComponent implements OnInit, AfterViewInit, OnDestroy {
  items: MenuItem[];
  @ViewChild('clientAutoComplete') clientAutoComplete: AutoComplete;
  editMode: boolean = false;
  isClientList: boolean = false;
  clientID: any = -1;
  clientViewChangeSubscription: Subscription;
  clientBadgeChangeSubscription: Subscription;
  selectedMenu: MenuItem;
  selectedMenuIndex: number = 0;
  allCounts: any = {
    contacts: 0,
    timeslips: 0,
    notes: 0,
    invoices: 0,
    payments: 0,
    clientID: -1,
    engagements: 0,
    projects: 0,
    todo: 0
  };
  selectedUrl: any;
  isIncludeAll = false;
  companyId: number = 0;

  private currentFocusedIndex = -1;

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private translate: TranslateService,
    public sharedSrvc: SharedComponentsService
  ) {
    this.mangoAPISrvc.notifyLogging(this.encrDecSrvc.getObject(AppConstants.isLoggingUser));
    this.mangoAPISrvc.applyLanguage();
    //   this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //     return false;
    // };
    this.clientViewChangeSubscription = this.mangoAPISrvc.clientViewChange$.subscribe(data => {
      this.mangoAPISrvc.applyLanguage();
      this.redirectScreens();
    });

    this.clientBadgeChangeSubscription = this.mangoAPISrvc.clienBadgeViewChange$.subscribe(data => {
      this.mangoAPISrvc.applyLanguage();
      this.getAllCounts();
    });
  }

  async ngOnInit() {
    //get from cache...
    if(!this.sharedSrvc.selClient)
      this.sharedSrvc.selClient = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);

    if (!this.sharedSrvc.isTableView && this.sharedSrvc.selClient?.ClientID) {
      await this.redirectToClientView(
        this.sharedSrvc.selClient.ClientID,
        this.sharedSrvc.selClient
      );
    }

    this.isClientListDropdownLoading = true;
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);

    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
  }

  ngOnDestroy() {
    clearInterval(this.intervalid);
    this.clientViewChangeSubscription.unsubscribe();
    this.clientBadgeChangeSubscription.unsubscribe();
  }

  focusOnItem(targetItem: any) {
    setTimeout(() => {
      const dropdownPanel = document.querySelector('.p-autocomplete-panel');
      if (!dropdownPanel) return;
      const itemElements = dropdownPanel.querySelectorAll('.p-autocomplete-item');
      itemElements.forEach((element: HTMLElement) => {
        if (element.textContent?.trim() === targetItem.ClientName) {
          element.scrollIntoView({ behavior: 'instant', block: 'nearest' }); 
          element.focus(); 
        }
      });
    });
  }
  handleClientKeyPress(event) { 
    if(!this.sharedSrvc.selClient) {
      this.currentFocusedIndex = -1;
    }   
    const key = event.key || event.code || event.which;
    if (key === 'ArrowDown') {
      if(this.currentFocusedIndex==-1){
        this.currentFocusedIndex=0;
      }else if(this.currentFocusedIndex<this.clientAutoComplete.suggestions.length-1){
        this.currentFocusedIndex++;
      }else if(this.currentFocusedIndex==this.clientAutoComplete.suggestions.length-1){
        this.currentFocusedIndex=0;
      }
    }else if (key === 'ArrowUp') {
      if(this.currentFocusedIndex==0){
        this.currentFocusedIndex=this.clientAutoComplete.suggestions.length-1;
      }else if(this.currentFocusedIndex>0){
        this.currentFocusedIndex--;
      }
    }else if(key === 'Enter'){
     this.redirectToClientView(this.sharedSrvc.selClient.ClientID, this.sharedSrvc.selClient);
    }
    else if(key === 'Backspace') {
      return;
    }
    if(this.currentFocusedIndex>=0){
      this.sharedSrvc.selClient=this.clientAutoComplete.suggestions[this.currentFocusedIndex];
      this.focusOnItem(this.sharedSrvc.selClient);
    }
  }


  ngAfterViewInit() {}

  addBadges() {
    const els = document.querySelectorAll('[class*=add-badge-]');
    for (const el of Array.from(els)) {
      let matches = el.className.match(/add-badge-(\S+)/);
      const badgeVal = matches ? matches[1] : '';
      const bmatches = el.className.match(/b-badge-(\S+)/);
      const badgebVal = bmatches ? bmatches[1] : '';
      
        const badgeText = badgeVal.replace(/\\\-/g, '__dash__').replace(/\-/g, ' ').replace('__dash__', '-');
        const badgeTextNode = document.createTextNode(badgeText);
        matches = el.className.match(/badge-class-(\S+)/);
        const badgeClass = matches ? matches[1] : 'danger';
        const badge = document.createElement('span');
        badge.classList.add('p-badge');
        badge.classList.add('p-ml-2');
        badge.classList.add('z-badge-' + badgebVal);
        if (parseInt(badgeVal) > 0) {
          badge.classList.add('bg-dark-blue');
        } else {
          badge.classList.add('bg-white');
          badge.classList.add('p-d-none');
        }
      badgeClass && badgeClass !== 'none' ? badge.classList.add('p-badge-' + badgeClass) : null;
      badge.appendChild(badgeTextNode);

      el.nextElementSibling.appendChild(badge);
      el.classList.remove('add-badge-' + badgeVal, 'badge-class-' + badgeClass);
    }
  }

  updateBadge() {
    const parent = this;
    const els = document.querySelectorAll('[class*=z-badge-]');
    for (const el of Array.from(els)) { 
      const matches = el.className.match(/z-badge-(\S+)/)
      const badgeVal = matches ? matches[1] : '';
      const newValue = parent.allCounts[badgeVal];
      el.classList.remove('bg-white');
      if (newValue > 0) {
        el.classList.remove('bg-white', 'p-d-none');
        el.classList.add('bg-dark-blue');
        el.textContent = newValue;
      } else {
        el.textContent = '0';
        el.classList.add('bg-white');
        el.classList.add('p-d-none');
      }
    }
  }

  removeClientCache() {
    this.encrDecSrvc.removeObject(AppConstants.selectedClientRecord);
    this.encrDecSrvc.removeObject(AppConstants.projectID);
    this.encrDecSrvc.removeObject(AppConstants.clientID);
    this.encrDecSrvc.removeObject(AppConstants.ClientName);
    this.encrDecSrvc.removeObject('selected-ClientEmail');
    this.encrDecSrvc.removeObject(AppConstants.contactsRoutePath + '_' + AppConstants.SearchString);
    this.encrDecSrvc.removeObject(AppConstants.Engagements + '_' + AppConstants.SearchString);
    this.encrDecSrvc.removeObject(AppConstants.Invoices + '_' + AppConstants.SearchString);
    this.encrDecSrvc.removeObject(AppConstants.mailRoutePath + '_' + AppConstants.SearchString);
    this.encrDecSrvc.removeObject(AppConstants.Notes + '_' + AppConstants.SearchString);
    this.encrDecSrvc.removeObject(AppConstants.Payments + '_' + AppConstants.SearchString);
    this.encrDecSrvc.removeObject(
      AppConstants.timeslipsRoutePath + '_' + AppConstants.SearchString
    );
  }

  redirectScreens() {
    this.clientID = this.encrDecSrvc.getObject(AppConstants.clientID);
    this.allCounts = this.encrDecSrvc.getObject(AppConstants.allDataCountsForClient);
    this.isClientList = this.clientID ? false : true;
    if (this.isClientList && this.clientID == '') {
      this.removeClientCache();
      this.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.listRoutePath]);
    } else if (this.clientID === -1) {
      this.removeClientCache();
      this.editMode = false;
      this.items = [
        {
          label: AppConstants.Main,
          routerLink: [AppConstants.viewRoutePath],
          icon: 'fas fa-user-circle'
        }
      ];
      this.selectedMenu = this.items[0];
      this.encrDecSrvc.addObject(AppConstants.clientID, -1);
      this.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath]);
    } else {
      this.setMenuIndex();
      this.editMode = true;
      const interval = setInterval(() => {
        if (!this.translate.translations[this.translate.currentLang]) return;
        clearInterval(interval);
        this.loadMenu();
      }, 300);
      this.router.navigate([this.selectedUrl]).then(response => {
        if (!response) {
          this.sharedSrvc.clientViewTrigger.next(true);
          this.addBadges();
        }
      });

      const interval1 = setInterval(() => {
        const els = document.querySelectorAll('[class*=add-badge-]');
        if(els.length == 0) return;
          clearInterval(interval1);
          this.addBadges();
      }, 300);
    }
  }

  loadMenu() {
    const clientRecord = this.encrDecSrvc.getObject(AppConstants.selectedClientRecord);
    const isContactRecord = (clientRecord && clientRecord['ContactRecord']) == true ? true : false;
    const subscription = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    if (isContactRecord) {
      this.items = [
        { label: this.translate.instant('main'), routerLink: [AppConstants.viewRoutePath], icon: `fal fa-user`, },
        { label: this.translate.instant('client.contacts'), routerLink: [AppConstants.contactsRoutePath], icon: `fal fa-users b-badge-contacts add-badge-${this.allCounts?.contacts} badge-class-danger` },
        { label: this.translate.instant('notes'), routerLink: [AppConstants.noteRoutePath], icon: `fal fa-sticky-note b-badge-notes add-badge-${this.allCounts?.notes} badge-class-danger` }
      ];
    } else if (subscription.toUpperCase() == 'PM') {
      this.items = [
        { label: this.translate.instant('main'), routerLink: [AppConstants.viewRoutePath], icon: `fal fa-user`, },
        { label: this.translate.instant('client.contacts'), routerLink: [AppConstants.contactsRoutePath], icon: `fal fa-users b-badge-contacts add-badge-${this.allCounts?.contacts} badge-class-danger` },
        { label: this.translate.instant('Projects'), routerLink: [AppConstants.projectsRoutePath], icon: `fal fa-tasks b-badge-projects add-badge-${this.allCounts?.projects} badge-class-danger` }
      ];
    } else {
      this.items = [
        { label: this.translate.instant('main'), routerLink: [AppConstants.viewRoutePath], icon: `fal fa-user`, },
        { label: this.translate.instant('Settings'), routerLink: [AppConstants.settingsRoutePath], icon: 'fal fa-cog' },
        { label: this.translate.instant('timer.engagement'), routerLink: [AppConstants.engagementsRoutePath], icon: `fal fa-building b-badge-engagements add-badge-${this.allCounts?.engagements} badge-class-danger` },
        { label: this.translate.instant('invoices'), routerLink: [AppConstants.invoicesRoutePath], icon: `fal fa-file-invoice b-badge-invoices add-badge-${this.allCounts?.invoices} badge-class-danger` },
        { label: this.translate.instant('client.payments'), routerLink: [AppConstants.paymentsRoutePath], icon: `fal fa-file-invoice-dollar` },
        { label: this.translate.instant('Time_&_Expense'), routerLink: [AppConstants.timeslipsRoutePath], icon: `fal fa-clock b-badge-timeslips add-badge-${this.allCounts?.timeslips} badge-class-danger` },
        { label: this.translate.instant('client.contacts'), routerLink: [AppConstants.contactsRoutePath], icon: `fal fa-users b-badge-contacts add-badge-${this.allCounts?.contacts} badge-class-danger` },
        { label: this.translate.instant('notes'), routerLink: [AppConstants.noteRoutePath], icon: `fal fa-sticky-note b-badge-notes add-badge-${this.allCounts?.notes} badge-class-danger` },
        { label: this.translate.instant('email_logs'), routerLink: [AppConstants.mailRoutePath], icon: 'fal fa-envelope' },
      ];

      if(subscription.toUpperCase() == "PRO" || subscription.toUpperCase() == "ENTERPRISE"  || subscription.toUpperCase() == "FREE"){
        this.items.push({ label: this.translate.instant('Projects'), routerLink: [AppConstants.projectsRoutePath], icon: `fal fa-tasks b-badge-projects add-badge-${this.allCounts?.projects} badge-class-danger` });
        this.items.push({ label: this.translate.instant('Schedule'), routerLink: [AppConstants.clientScheduleRoutePath ], icon: 'fal fa-calendar' });
        this.items.push({ label: this.translate.instant('Budgets'), routerLink: [AppConstants.budgetsRoutePath ], icon: 'fal fa-ballot' });
      }
      this.items.push({ label: this.translate.instant('QuickLinks.to-do'), routerLink: [AppConstants.clientTodo], icon: `fal fa-list-alt b-badge-todos add-badge-${this.allCounts?.todos} badge-class-danger` });
    }
    this.selectedMenu = this.items[this.selectedMenuIndex];
  }

  setMenuIndex() {
    const url = this.router.url;
    this.selectedUrl = "";
    switch (url) {
      case '/client/settings':
        this.selectedMenuIndex = 1;
        this.selectedUrl = 'client/settings';
        break;
      case '/client/engagements':
        this.selectedMenuIndex = 2;
        this.selectedUrl = 'client/engagements';
        break;
      case '/client/invoices':
        this.selectedMenuIndex = 3;
        this.selectedUrl = 'client/invoices';
        break;
      case '/client/payments':
        this.selectedMenuIndex = 4;
        this.selectedUrl = 'client/payments';
        break;
      case '/client/timeslips':
        this.selectedMenuIndex = 5;
        this.selectedUrl = 'client/timeslips';
        break;
      case '/client/contacts':
        this.selectedMenuIndex = 6;
        this.selectedUrl = 'client/contacts';
        break;
      case '/client/notes':
        this.selectedMenuIndex = 7;
        this.selectedUrl = 'client/notes';
        break;
      case '/client/mail':
        this.selectedMenuIndex = 8;
        this.selectedUrl = 'client/mail';
        break;
      case '/client/projects':
        this.selectedMenuIndex = 9;
        this.selectedUrl = 'client/projects';
        break;
      case '/client/schedule':
        this.selectedMenuIndex = 10;
        this.selectedUrl = 'client/schedule';
      case '/client/budgets':
        this.selectedMenuIndex = 11;
        this.selectedUrl = 'client/budgets';
        break;
      case '/client/todo':
        this.selectedMenuIndex = 12;
        this.selectedUrl = 'client/todo';
        break;
      default:
        this.selectedMenuIndex = 0;
        this.selectedUrl = 'client/view';
    }
  }

  getAllCounts() {
    const parent = this;
    const clientId = this.encrDecSrvc.getObject(AppConstants.clientID);
    parent.encrDecSrvc.removeObject(AppConstants.allDataCountsForClient);
    if (clientId && clientId > 0) {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getAllDataCounts(clientId).subscribe(function (data) {
        parent.allCounts = data;
        parent.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
        parent.updateBadge();
        parent.mangoAPISrvc.showLoader(false);
      });
    }
  }
  onIncludeAllChange() {
    this.isClientListDropdownLoading = true;
    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  intervalid = null;
  isClientListDropdownLoading = false;
  fetchClients() {
    const clientUpdated = this.encrDecSrvc.getObject(AppConstants.clientUpdated);
    if (
      this.clientListsDropdown.length == 0 ||
      (this.isIncludeAll &&
        this.clientListsDropdown.length !== this.encrDecSrvc.clientList.length) ||
      (!this.isIncludeAll &&
        this.clientListsDropdown.length !==
          this.encrDecSrvc.clientList.filter(
            client => !client['Inactive'] && !client['ContactRecord']
          ).length) ||
      clientUpdated
    ) {
      // let list = this.encrDecSrvc.clientList;
      if (this.isIncludeAll) {
        this.clientListsDropdown = [...this.encrDecSrvc.clientList];
      } else {
        this.clientListsDropdown = [
          ...this.encrDecSrvc.clientList.filter(
            client => !client['Inactive'] && !client['ContactRecord']
          )
        ];
      }
      // let idArray = this.totalClientsDataSource.map((client) => client.ClientID);
      // for (let i = 0; i < list.length; i++) {
      //   let item = list[i];
      //     this.clientListsDropdown.push(item);
      //     // this.filteredClients.push(item);
      // }
      if (clientUpdated) {
        this.isClientListDropdownLoading = false;
        clearInterval(this.intervalid);
      }
    } else {
      this.isClientListDropdownLoading = false;
      clearInterval(this.intervalid);
    }
    this.encrDecSrvc.removeObject(AppConstants.clientUpdated);
  }

  async redirectToClientView(clientId: number, rowData: any) {
    const payload = {
      ClientID: clientId,
      CompanyID: this.companyId
    };
    let resp = await this.mangoAPISrvc.updateAllProjectHeaderCompleted(payload);
    this.isClientList = false;
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, rowData);
    this.encrDecSrvc.addObject(AppConstants.clientID, clientId);
    this.encrDecSrvc.addObject(AppConstants.ClientName, rowData['ClientName']);
    // this.encrDecSrvc.addObject(
    //   AppConstants.selectedClientTabIndex,
    //   this.selectedTabIndex
    // );
    this.mangoAPISrvc.fireClientView(true);
    this.selectedMenuIndex = 0;
    if(this.items && this.items.length > 0) {
      this.selectedMenu = this.items[this.selectedMenuIndex];
    }
    this.router.navigate([AppConstants.clientRoutePath + "/" + AppConstants.viewRoutePath]).then(() => {
      this.clientAutoComplete?.focusInput();
    });
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  clientListsDropdown = [];
  filteredClients = [];
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }
    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientListsDropdown.length; i++) {
        const client = this.clientListsDropdown[i];
        if (
          client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1
        ) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }
}
